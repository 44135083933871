@import "./var.scss";
@import "./fonts.scss";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.icons_container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
}

.text {
    font-size: .9rem;
}

.icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    cursor: pointer;
    span {
        align-content: center;
        width: 100%;
    }
}

@media (max-width: 576px){
    .icons_container{
        width: 100%;
    }
}