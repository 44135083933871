@import "./var.scss";
@import "./fonts.scss";

.entidad {
    border: 1px solid $gris-claro;
    border-radius: 0.521vw;
    padding: 1vw;
    min-height: 10vw;
}

.icon {
    color: $naranja;
    position: relative;
    padding-bottom: 0.5vw;
    font-size: 1.5vw;
    line-height: 0;
}

.parrafo {
    line-height: 1.2;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//big desktop
@media (max-width: 1200px) {
    .entidad {
        min-height: 10rem;
    }
}

//only tablet pro
@media only screen and (min-width: 992px) and (max-width: 1200px) and (orientation: portrait) {
    .entidad {
        min-height: 15rem;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .entidad {
        border-radius: 0.521rem;
        padding: 1rem;
    }

    .icon {
        padding-bottom: 0.5rem;
        font-size: 1.5rem;

    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {}

//only phone
@media (max-width: 576px) {
    .entidad {
        border-radius: 0.521rem;
        padding: 1rem;
    }

    .icon {
        padding-bottom: 0.5rem;
        font-size: 1.5rem;

    }
}