@import "./var.scss";
@import "./mixin.scss";

.border_gris_claro {
	border: 1px solid $gris-claro;
 
	&:hover {
		background-color: white;
		border: 1px solid $gris-claro;
	}
}

.border_naranja {
	border: 1px solid $naranja;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;

	p {
		font-weight: 400;
	}

	&:hover {
		background-color: $naranja !important;
		border: 1px solid $naranja !important;
		cursor: pointer;

		svg {
			color: white;
		}

		p {
			font-weight: 700;
			color: white;
		}
	}
}

.border_gris {
	border: 1px solid $gris-claro !important;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
    color: $naranja !important;
	p {
		font-weight: 400;
	}

	&:hover {
		border: 1px solid $gris-claro;
		-webkit-box-shadow: -1px 7px 31px -10px rgba(0, 0, 0, 0.72);
		-moz-box-shadow: -1px 7px 31px -10px rgba(0, 0, 0, 0.72);
		box-shadow: -1px 7px 31px -10px rgba(0, 0, 0, 0.72);
		cursor: pointer;
	}
}

.size_box {
	width: 10.5vw;
	height: 8vw;
}

.w_custom_img {
	width: 50px;
	display: block;
}

.formInput {
	width: 11vw;
	height: 3.5vw;
	border: 1px solid $naranja;
	padding: 0.3vw 0.7vw;
	line-height: 100%;
	border-radius: 10px;
}

input.no_blue {
	background-color: white;

	&:active,
	&:focus {
		outline: none !important;
		outline-width: 0 !important;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}
}

.labels_filter {
	label {
		text-transform: none !important;
	}

}

.tipo_inmuebles {
	width: 50%;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.fontChild {
		font-size: 1rem;
	}

	.formInput {
		width: 10rem;
		height: 4rem;
		padding: 0.3rem 0.7rem;
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.fontChild {
		font-size: 1rem;
	}

	.formInput {
		width: 10rem;
		height: 4rem;
		padding: 0.3rem 0.7rem;
	}
}

// tablet MD
@media (min-width: 577px) and (max-width: 767px) and (orientation: landscape) {
	.fontChild {
		font-size: 1rem;
	}

	.formInput {
		width: 8rem;
		height: 3.5rem;
		padding: 0.3rem 0.7rem;
	}

}

@media (max-width: 767px) {
	.tipo_inmuebles {
		width: 45%;
	}
}

//only phone
@media (max-width: 576px) {
	.fontChild {
		font-size: 1rem;
	}

	.formInput {
		width: 8rem;
		height: 3.5rem;
		padding: 0.3rem 0.7rem;
	}
}