@import "./var.scss";
@import "./mixin.scss";

.circulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

}

.icons {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    cursor: pointer;
    @include toAnim(0.3s);
    box-shadow: 0px 0px 2px 2px $gris-claro;
    padding: 0.9vw;
}

.icon {
    font-size: 1.8vw;
    color: $naranja;
}

.nombre {
    line-height: 1;
    margin-top: 0.5vw;
    font-size: 0.9vw;
    text-align: center;
    color: $gris;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .icon {
        font-size: 1.5rem;
    }

    .icons {
        padding: 0.7rem;
    }

    .nombre {
        font-size: 0.8rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {}


@media (max-width: 767px) {
    .icons {
        padding: 0.9rem;
    }
    .icon {
        font-size: 1.5rem;
    }

    .nombre {
        font-size: 0.8rem;
    }
}

//only phone
@media (max-width: 576px) {
    .icon {
        font-size: 1.5rem;
    }

    .icons {
        padding: 0.7rem;
    }

    .nombre {
        font-size: 0.8rem;
    }
}