@import "./var.scss";
@import "./fonts.scss";

.tooltip_container {
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer;

}

.marginLe {
  margin-left: 1.8vw;
}


.tooltip_arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-right: 2vw solid $naranja;
  border-left: 0.521vw solid transparent;
  border-bottom: 0.417vw solid transparent;
  border-top: 0.417vw solid transparent;
  top: 40%;
  left: -12%;
  transform: translateX(-50%);

}

.tooltip_content {}

.tooltip_text {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  background-color: $naranja;
  color: #fff;
  padding: 0.2vw 0.5vw;
  border-radius: 0.521vw;
  opacity: 0;
  transition: opacity 0.3s;
  line-height: 1.2;
  visibility:hidden;
}

.tooltip_text_icon {
  width: auto;
  background-color: $naranja;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 0.6vw;
  position: absolute;
  z-index: 99;
  bottom: 125%;
  left: auto;
  margin-left: 40%;
  opacity: 0;
  visibility:hidden;
  transition: opacity 0.3s;
  white-space: nowrap;
  /* Evitará que el texto se rompa a la siguiente línea */
  transform: translateX(-50%);
  font-size: 0.8vw;
}

.tooltip_text_new {
  position: absolute;
  width: 40%;
  transform: translateY(-120%);
  background-color: $gris-claro;
  color: $gris;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 0.6vw;
  border-radius: 0.521vw;
  opacity: 0;
  transition: opacity 0.3s;
  line-height: 1.2;
  z-index: 1;

  .tooltip_arrow {
    position: absolute;
    border-left: 0.5vw solid transparent;
    border-right: 0.5vw solid transparent;
    border-top: 1.2vw solid #e6e7e9;
    top: 100%;
    left: 90%;
    transform: translateX(-50%);
  }

  p {
    margin: 0;
  }
}

.tooltip_container:hover .tooltip_text {
  opacity: 1;
  visibility: visible;
}

.tooltip_container:hover .tooltip_text_icon {
  opacity: 1;
  visibility: visible;
}


//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
  .tooltip_text {
    padding: 0.2rem 0.9rem;
    border-radius: 0.521rem;
  }

  .tooltip_arrow {
    border-right: 2rem solid $naranja;
    border-left: 0.521rem solid transparent;
    border-bottom: 0.417rem solid transparent;
    border-top: 0.417rem solid transparent;
  }

  .tooltip_text_icon {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
  .tooltip_arrow {
    border-right: 2rem solid $naranja;
    border-left: 0.521rem solid transparent;
    border-bottom: 0.417rem solid transparent;
    border-top: 0.417rem solid transparent;

  }

  .tooltip_text_icon {
    font-size: 0.8rem;
  }

}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .tooltip_arrow {
    border-right: 2rem solid $naranja;
    border-left: 0.521rem solid transparent;
    border-bottom: 0.417rem solid transparent;
    border-top: 0.417rem solid transparent;

  }

  .tooltip_text {
    padding: 0.2rem 0.5rem;
    border-radius: 0.521rem;
  }

  .tooltip_text_icon {
    padding: 0.6rem;
    font-size: 0.7rem;
  }
}

//only phone
@media (max-width: 576px) {
  .tooltip_arrow {
    border-right: 2rem solid $naranja;
    border-left: 0.521rem solid transparent;
    border-bottom: 0.417rem solid transparent;
    border-top: 0.417rem solid transparent;
    top: 39%;
  }

  .tooltip_text {
    padding: 0.2rem 0.5rem;
    border-radius: 0.521rem;
  }

  .tooltip_text_icon {
    padding: 0.6rem;
    font-size: 0.7rem;
  }
}