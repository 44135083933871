.container {
    width: 90%;
    margin: 0 auto;
}

.container_pdf {
    max-width: 950px;
    margin: 0 auto;
}

.offset_col {
    margin-left: 10%;
}

.contenedor-img {
    width: 100%;
}

.img {
    width: 100%;
}


.titulos {
    font-size: 32px;
    font-weight: 500;
    color: #57585A;
}

.parrafos {
    font-size: 16px;
    color: #57585A;
}


.subtitulos {
    font-size: 24px;
    font-weight: 500
}

.margen {
    margin-bottom: 0px;
}

.cuadro {
    border: 1px solid #D9D9D9;
    padding: 12px 40px;
    border-radius: 20px;
}

.cuadro-tabla {
    position: relative;
    border: 1px solid #D9D9D9;
    padding: 12px;
    border-radius: 20px;
}

.cuadro-dobles {
    border: 1px solid #D9D9D9;
    border-radius: 20px;
}



.color_gris {
    color: #57585A;
}

.fw_400 {
    font-weight: 400;
}

.fw_500 {
    font-weight: 500;
}

.fw_700 {
    font-weight: 700;
}

.fz_10 {
    font-size: 10px;
}


.fz_12 {
    font-size: 12px;
}

.fz_11 {
    font-size: 11px;
}

.fz_16 {
    font-size: 14px;
}

.fz_24 {
    font-size: 18px;
}

.fz_14 {
    font-size: 14px;
}

.fz_32 {
    font-size: 27px;
}

.card_titles {
    position: relative;
    margin-bottom: 20px;
}

.text_right {
    text-align: right;
}

.cuadro_infoPersonal {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 6px 1px 15px 1px;
}

.cuadro_infodoble {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 8px 1px 15px 1px;
}

.tablainfoIn {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 6px 12px 15px 12px;
}

.tablainfoIndate {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 0.2rem;
}

.row1 {
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 1rem;
}

.paginaNueva {
    page-break-before: always;
}

.table {
    width: 100%;

    tr {
        text-align: center;
        background-color: transparent;
        border: none;
    }

    thead {
        background-color: transparent;
    }

    tbody {
        border: none;
    }

    td {
        border: none;
        padding: 0;
    }

    th {
        padding: 0;
    }
}

.wpcuadro {
    width: 50%;
}

.wptabla {
    width: 50%;
}

.espacificaciones {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    row-gap: 1rem;
}

.wp_fila {
    width: 33.33%;

    img {
        width: auto;
        max-height: 9.5rem;
    }
}

.pt3 {
    margin-top: 0.3rem;
}


.contenpage {
    position: relative;
    // height: 10.9in;
    min-height: 11.68in;
}

.contenpagedescarga {
    position: relative;
    height: 10.91in;
}

.hl {
    line-height: 1;
}

.containerPageViewer {
    position: relative;
    width: 80%;
    background: white;
    padding: 1.5vw;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    max-height: 100vh;
    overflow-y: auto;
}

.iconPdf {
    width: auto;
    max-width: 1.7rem;
}

.imgPie {
    position: absolute;
    bottom: 0;
    left: 0;
}

.ViewerPdf {
    height: 450px;
}

.modalPdfViewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0 0 0 / 44%);
    z-index: 9999;
    max-height: 100vh;
    overflow-y: auto;
}

//big desktop
@media (min-width: 1920px) {
    .ViewerPdf {
        height: 770px;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .containerPageViewer {
        position: relative;
        width: 80%;
        padding: 1.5rem;
    }

    .valorpdf {
        position: relative;
        padding-bottom: 1rem;
    }

    .btnPdf {
        button {
            position: relative;
            // margin-top: 1rem;
        }
    }

}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .ViewerPdf {
        height: 470px;
    }
}

@media (max-width: 767px) {
    .valorpdf {
        position: relative;
        padding-bottom: 1rem;
    }

    .btnPdf {
        button {
            position: relative;
            margin-top: 1rem;
        }
    }

    .containerPageViewer {
        padding: 1.5rem;
    }
}

@media (max-width: 576px) {
    .containerPageViewer {
        position: relative;
        width: 90%;
        padding: 1.5rem;
    }

    .valorpdf {
        position: relative;
        padding-bottom: 1rem;
    }

    .btnPdf {
        button {
            position: relative;
            margin-top: 1rem;
        }
    }

    .modalPdfViewer {

        z-index: 9999;
    }

    .ViewerPdf {
        height: 350px;
    }
}

@media (max-height: 600px) {
    .ViewerPdf {
        height: 400px;
    }
}