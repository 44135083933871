.galeria {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


.SwiperGalery {
    overflow: initial;
}

.img_items {
    position: relative;
    overflow: hidden;
    padding: 0.25vw;
    /* padding-left: 0.5vw;
    padding-top: 0.5vw; */
}

.img_itemsv1 {
    width: 66.66%;
    height: 22vw;
}

.img_itemsv2 {
    width: 33.33%;
    height: 22vw;
}

.img_itemsv3 {
    width: 33.33%;
    height: 15vw;
}

.imgen {
    object-fit: cover;
    //object-position: bottom;
    height: 100%;
    width: 100%;
}

.buttonLink {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}

.overlayLink {
    position: absolute;
    width: calc(100% - 0.5vw);
    height: calc(100% - 0.5vw);
    top: 0.25vw;
    left: 0.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(black, 0.6);
}

.imgslider {
    height: 35vw;
    width: auto !important;
    object-fit: cover;
}

.btn_mas {
    font-size: 1.5vw;
}

.pie_container {
    position: relative;
    //width: 100%;
}

.pie_img {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #57585AB2;
    width: 100%;
    padding: 1vw 2.5vw;
}

.texto_pieImg {
    width: 30%;
}

.Container_videoHeight {
    height: 35vw;
    width: 50vw;
}

.wpSlide {
    width: auto;
}

//big desktop
@media (min-width: 1920px) {}

@media (min-width: 1600px) {
    .imgslider {
        width: 100% !important;
    }
    .Container_videoHeight {
        width: 53rem;
    }
}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .pie_img {
        padding: 1rem 2.5rem;
    }

    .texto_pieImg {
        width: 70%;
    }
    .imgslider {
        width: 100% !important;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .pie_img {
        padding: 1rem 2.5rem;
    }

    .texto_pieImg {
        width: 40%;
    }
}
//only phone
@media (max-width: 767px) {
    .imgslider {
        width: 100% !important;
    }
}

//only phone
@media (max-width: 576px) {

    .wpSlide {
        width: 100%;
    }

    .Container_videoHeight {
        height: 30rem;
        width: 100%;
    }

    .pie_img {
        padding: 1rem 2.5rem;
    }

    .texto_pieImg {
        width: 40%;
    }

    .galeria {
        justify-content: flex-start;
    }

    .img_itemsv1 {
        position: relative;
        width: 100%;
        padding: 0.15rem;
        height: 10rem;
    }

    .img_itemsv2 {
        padding: 0.15rem;
        height: 3.5rem;
        width: 50%;
        height: 10rem;
    }

    .img_itemsv3 {
        padding: 0.15rem;
        width: 50%;
        height: 10rem;
    }

    .overlayLink {
        position: absolute;
        width: calc(100% - 0.5rem);
        height: calc(100% - 0.5rem);
        top: 0.15rem;
        left: 0.15rem;

    }

    .imgslider {
        height: 30rem;
        width: 100% !important;
    }

    .btn_mas {
        font-size: 1rem;
    }
}