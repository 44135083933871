@import "./var.scss";
@import "./mixin.scss";
@import "./fonts.scss";

.footer {
    z-index: 1040;
    width: 100%;
    //background-color: $gris;
    background: #f7f7f7;
    flex-shrink: 0;
    position: relative;
    // background-image: url("../../public/img/fondo_footer.png");
    background-size: cover;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.hr {
    border-color: $naranja;
    opacity: 1;
    margin: 2rem 0rem 1rem 0rem;
}

.contentLogo {
    //background-color: white;
    display: flex;
    justify-content: flex-start;
    margin-left: 3vw;
    // padding: 0.5vw 2vw 0.5vw 0;
    -webkit-border-top-right-radius: 60px;
    -webkit-border-bottom-right-radius: 60px;
    -moz-border-radius-topright: 60px;
    -moz-border-radius-bottomright: 60px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
}

/*.logo {
    margin-left: 7.5vw;
}*/

.socialLink {
    color: white;
    font-size: 3.8vw;
}

.hvimg {
    height: 2rem;
    position: relative;
    margin-right: 0.6vw;
}

.iconDescarga {
    width: auto;
    height: 2.5vw;
}

// big desktop
@media only screen and (min-width: 1920px) {
    .iconDescarga {
        height: 2.5rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px) {
}

@media (max-width: 1200px) {
    .socialLink {
        font-size: 2.5rem;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .iconDescarga {
        height: 2rem;
    }
    .contentLogo {
        margin-left: 3rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .contentLogo {
        margin-left: 2.5rem;
    }
}

@media (max-width: 767px) {
    .contentLogo {
        justify-content: center;
        margin-left: 0rem;
    }

    .iconDescarga {
        width: auto;
        height: 2.5rem;
    }
}

@media (max-width: 576px) {
}
