@import "../../../styles/scss/var.scss";

$text_color: $gris;
$text_color_variant: white;
$background_color: $naranja;
$background_color_on_hover: #ccc;


.options_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    min-width: "3rem";
    margin-bottom: 10px;
}

.option_container {
    border: 1px solid $background_color;
    border-radius: 10px;
    font-size: 0.85rem;
    cursor: pointer;
}

.option_container_selected {
    background-color: $background_color;

    span {
        color: $text_color_variant;
    }
}

.option_container:hover {
    background-color: $background_color_on_hover ;
}

.option_text {
    color: $text_color;
    font-weight: 400;
}