.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding: 28px;
    margin-top: 100px;
}

.title {
    font-size: 2.5rem;
    font-weight: bold;

}

.text {
    font-size: 1.3rem;
    align-self: center;
    margin: auto;
}

.separator {
    display: none;
    background-color: gray;
    height: 0.2px;
    width: 75%;
    margin: 5px 0 5px 0;
}

@media (min-width: 576px) {
    .container {
        flex-direction: row;
        height: 50vh;
        margin: auto;
    }

    .title {
        align-self: center;
    }

    .text {
        width: 50%;
        text-align: left;
        margin: 0px
    }

    .separator {
        display: flex;
        width: 2.5px;
        height: 2.5rem;
        margin: 0px 20px 0px 20px;
        align-self: center;
    }
}