@import "./var.scss";

$text_color: $gris;

.iframe_container {
    width: 100%;
    height: 37vw;
}

.iframe_text {
    color: $text_color;
}

.iframe {
    width: 100%;
    height: 100%;
    border-radius: 1rem
}

@media (min-width: 1920px) {
    .iframe_container {
        height: 29vw;
    }
}

@media (min-width: 1201px) {
    .iframe_container{
        height: 37vw;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .iframe_container {
        height: 37rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .iframe_container {
        height: 32rem;
    }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
    .iframe_container {
        height: 32rem;
    }
}

@media (max-width: 576px)  {
    .iframe_container {
        height: 37rem;
    }
}