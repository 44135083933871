@import "./var.scss";
@import "./mixin.scss";


.btn,
.btnSecundary,
.btnDisabled {
	font-size: 1.2vw;
	padding: 0.5vw 3vw;
	font-weight: 600;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	text-decoration: none;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	display: inline-block;
	white-space: nowrap;
	@include toAnim(0.3s);
	border: 0;
	position: relative;
}

.btnDescargar {
	padding: 0.5vw 1vw;
	border: 0;
	background-color: #fff;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	text-decoration: none;
	color: $naranja;

}

.btnSmall {
	font-size: 0.8vw;
	//padding: 0.3vw 1.5vw;
}

.btn {
	background-color: $naranja;
	color: white;
	position: relative;
}

.btnblanco {
	background-color: white;
	color: $naranja;
	position: relative;
}

.calcelar_btn {
	border: 1px solid rgb(87 88 90 / 13%);
	border-radius: 10px;
	background-color: white;
	color: $naranja;

	&:hover {
		box-shadow: 0px 0px 10px 0px #00000040;
		background-color: white;
		color: $naranja;
		border: 1px solid rgb(87 88 90 / 13%);
	}
}

.btnTarjetaP {
	border: 1px solid $naranja;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;
	color: $naranja;
	padding: 0.7vw 0vw;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.btn:hover {
	background-color: white;
	color: $naranja;
}

.btnblanco:hover {
	background-color: $naranja;
	color: white;
}

.btnblanco:active {
	background-color: $naranja;
	color: white;
}

.btnblanco:hover .wpChild {
	color: white !important;
}

.btn:hover .wpChild {
	color: $naranja;
}

.btn:active {
	background-color: white;
	color: $naranja;
}

.btn:active .wpChild {
	color: white;
}

.wpChild {
	@include toAnim(0.3s);
}

.btnFlex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn .loadingContainer,
.btnSecundary .loadingContainer {
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	@include toAnim(0.3s);
}

/* .btn:hover .loadingContainer,
.btnSecundary:hover .loadingContainer {
    opacity: 1;
} */

.btn:active .loadingContainer,
.btnSecundary:active .loadingContainer {
	opacity: 1;
}

/* .btn:hover .wpChild,
.btnSecundary:hover .wpChild {
    opacity: 0.2;
} */

.btn:active .wpChild,
.btnSecundary:active .wpChild {
	opacity: 0.2;
}

/* .btn:active .loadingContainer .wpChild,
.btnSecundary:active .loadingContainer .wpChild {
    opacity: 1;
} */

.active .loadingContainer {
	opacity: 1;
}

.active .wpChild {
	opacity: 0.2;
}

.btn .dot {
	background-color: $naranja;
}

.btnSecundary .dot {
	background-color: white;
}

.dot {
	display: inline-block;
	margin-left: 2px;
	opacity: 1;
	animation: appear 3s infinite;
	/* font-size: 1.2vw; */
	width: 1rem;
	height: 1rem;
	margin: 0 0.2rem;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.dot:nth-child(1) {
	animation-delay: 1s;
}

.dot:nth-child(2) {
	animation-delay: 1.5s;
}

.dot:nth-child(3) {
	animation-delay: 2s;
}

.hvImg {
	max-height: 3.5vw;
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	33% {
		opacity: 1;
	}

	66% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.btnSecundary {
	background-color: white;
	color: $naranja;
	border: 1px solid $naranja;
}

.btnSecundary:hover {
	background-color: $naranja;
	color: white;
	border-color: $naranja;
}

.btnSecundary:hover .wpChild {
	color: white;
}

.btnSecundary:active {
	color: white;
	background-color: $naranja;
	border-color: $naranja;
}

.btnSecundaryv2 {
	background-color: $naranja;
	color: white;
	border: 1px solid $naranja;
}

.btnSecundaryv2:hover {
	background-color: white;
	color: $naranja;
	border-color: $naranja;
}

.btnSecundaryv2:active {
	background-color: white;
	color: $naranja;
	border: 1px solid $naranja;
}

.btn:disabled,
.btnDisabled {
	background-color: white !important;
	color: #cccccc;
	pointer-events: none;
}


.btnpagos {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	position: relative;
}

.link {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.icon {
	display: inline-flex;
	font-size: 1.5vw;
	color: #939393;
}

.btnOrdenar {
	border: 0;
	background-color: transparent;
	line-height: 100%;
}

.textbtn {
	border-bottom: 1px solid $gris;
	font-size: 0.833vw;
	color: $gris;
}

.btnadd {
	border-radius: 50%;
	background-color: $naranja;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 6px 6px -2px #00000040;
	height: 3vw;
	width: 3vw;
	font-size: 2vw;

	button {
		background-color: transparent;
		border: 0;
		display: inline-flex;
		color: white;
	}

}

.wpbtnIngresar {
	width: 70%;
}

.btn_inactivo {
	opacity: 0.3;
	pointer-events: none;
	background-color: $gris_2;
}

.btnCopiar {
	border: 0;
	background-color: transparent;
	width: 1.2vw;
}

.btnEliminar_codigo {
	background-color: transparent;
	border: 0;
	color: $gris;
	border-bottom: 1px solid $gris-claro;
	font-size: 0.833vw;
}

.pBtnSearch {
	padding: .5vw 5vw;
}

.btnPregunta {
	position: fixed;
	bottom: 7vw;
	right: 1.5vw;
	height: 4vw;
	width: 4vw;
	background-color: white;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	border: 0;
	box-shadow: 0px 0px 10px 0px #00000033;

}

.iconbntPregunta {
	font-size: 2.3vw;
	color: $naranja;
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn_verDetalle {
	border-bottom: 1px solid $gris;

}

.number_notification {
	position: absolute;
	right: 0;
	top: 0;
	border-radius: 50%;
	width: 1.3vw;
	height: 1.3vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $naranja;
	color: white;
	transform: translateX(6px);
}

.btnNotification {
	position: relative;
}

.img_btnNotificacion {
	height: 1.7vw;
}

.btn_config {
	background-color: transparent;
	border: 0;
	font-size: 1.5vw;
	display: inline-flex;
	color: $gris;
}

.containerBtn_config {
	border: 1px solid #78787880;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	padding: 1vw;
	display: inline-flex;
}

// big desktop
@media only screen and (min-width: 1921px) {
	.containerBtn_config {
		padding: 1rem 1.5rem;
	}

	.img_btnNotificacion {
		height: 2.2rem;
	}

	.hvImg {
		max-height: 3rem;
	}

	.btnDescargar,
	.btn,
	.btnSecundary {
		font-size: 1.1rem;
		padding: 0.5rem 3rem;
	}

	.btnSmall {
		font-size: 0.8rem;
		padding: 0.3rem 3rem;
	}

	.number_notification {
		width: 1.4rem;
		height: 1.4rem;

	}
}

@media (max-width: 1600px) {
	.number_notification {
		width: 1rem;
		height: 1rem;
	}

	.btn,
	.btnSecundary {
		font-size: 1.2vw;
	}

	.btnSmall {
		font-size: 0.9vw;
	}
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
	.pBtnSearch {
		padding: .5rem 5rem;
	}
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

	.containerBtn_config {
		padding: 1rem 1.5rem;
	}

	.btn_config {
		font-size: 1.5rem;
	}

	.img_btnNotificacion {
		height: 2rem;
	}

	.number_notification {
		transform: translateX(9px);
	}

	.number_notification {
		width: 1.4rem;
		height: 1.4rem;

	}

	.btn {
		white-space: wrap;
		font-size: 0.9rem;
		padding: 0.5rem 1rem;
	}

	.btnPregunta {
		bottom: 8rem;
		right: 1.5rem;
		height: 4.5rem;
		width: 4.5rem;
	}


	.iconbntPregunta {
		font-size: 2.8rem;
	}

	.btnEliminar_codigo {
		font-size: 0.833rem;
	}


	.btnSecundary {
		font-size: 0.9rem;
		padding: 0.5rem 3rem;
	}

	.btnDescargar {
		padding: 0.5rem 1rem;
		font-size: 0.9rem;

	}

	.icon {
		font-size: 1.5rem;
	}

	.btnTarjetaP {
		padding: 0.7rem 0rem;
	}
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.containerBtn_config {
		padding: 1rem 1.5rem;
	}
	.btn_config {
		font-size: 1.5rem;
	}

	.number_notification {
		transform: translateX(9px);
	}

	.img_btnNotificacion {
		height: 1.9rem;
	}

	.number_notification {
		width: 1.3rem;
		height: 1.3rem;
	}

	.btnTarjetaP {
		padding: 0.7rem 0rem;
	}

	.btnPregunta {
		bottom: 8rem;
		right: 1.5rem;
		height: 4.5rem;
		width: 4.5rem;
	}


	.iconbntPregunta {
		font-size: 2.8rem;
	}

	.btnEliminar_codigo {
		font-size: 0.833rem;
	}

	.btnCopiar {
		width: 1.5rem;
	}

	.btnSecundary {
		font-size: 0.9rem;
		padding: 0.5rem 3rem;
	}

	.btn {
		white-space: wrap;
		font-size: 0.9rem;
		padding: 0.5rem 1rem;
	}

	.btnDescargar {
		padding: 0.5rem 1rem;
		font-size: 1.5rem;
	}

	.icon {
		font-size: 1.5rem;
	}

	.textbtn {
		font-size: 0.833rem;
	}

	.btnadd {
		height: 3.5rem;
		width: 3.5rem;
		font-size: 2rem;

	}
}

// tablet MD
@media (max-width: 768px) {

	.btn_config {
		font-size: 1.5rem;
	}

	.btnEliminar_codigo {
		font-size: 0.833rem;
	}

	.textbtn {
		font-size: 0.833rem;
	}

	.btn,
	.btnSecundary {
		font-size: 1rem;
		padding: 0.3rem 3rem;
	}

	.icon {
		font-size: 1.5rem;
	}

	.btnadd {
		height: 3rem;
		width: 3rem;
		font-size: 2rem;

	}

	.btnCopiar {
		width: 1rem;
	}

	.pBtnSearch {
		padding: .5rem 5rem;
	}
}

@media (max-width: 767px) {
	.containerBtn_config {
		padding: 1rem 1.5rem;
	}

	.number_notification {
		transform: translateX(9px);
	}

	.img_btnNotificacion {
		height: 2rem;
	}

	.number_notification {
		width: 1.4rem;
		height: 1.4rem;

	}

	.btnPregunta {
		bottom: 6rem;
		right: 1.5rem;
		height: 2.8rem;
		width: 2.8rem;
	}


	.iconbntPregunta {
		font-size: 1.8rem;
	}

	.btnTarjetaP {
		padding: 0.7rem 0rem;
	}

	.hvImg {
		max-height: 2.5rem;
	}
}

// only mobile

@media (max-width: 576px) {
	.btn_buscar {
		width: 100%;
	}

	.btnPregunta {
		bottom: 9.5rem;
		right: 1.5rem;
		height: 3.5rem;
		width: 3.5rem;
	}


	.iconbntPregunta {
		font-size: 2rem;
	}

	.btnEliminar_codigo {
		font-size: 0.833rem;
	}

	.btnCopiar {
		width: 1.5rem;
	}

	.btnadd {
		height: 3rem;
		width: 3rem;
		font-size: 2rem;

	}

	.textbtn {
		font-size: 0.833rem;
	}

	.icon {
		font-size: 1.5rem;
	}

	.btn,
	.btnSecundary {
		font-size: 1rem;
		padding: 0.3rem 3rem;
	}

	.btnSmall {
		font-size: 0.8rem;
		padding: 0.3rem 1.5rem;
	}

	.btnDescargar {
		padding: 0.5rem 1rem;
		font-size: 1rem;
	}

	.pBtnSearch {
		padding: 0.5rem 8rem;
	}
}