$naranja: #f47921;
$naranja-rgb: 244, 121, 33;
$ladrillo: #d1581a;
$ladrillo-oscuro: #bd3b17;
$ocre: #e5a710;

$gris: #57585a;
$gris-claro: #e6e7e9; 
$gris_2: #C1C1C1;
$gris_3: #CBCBCB;
$gris_4: #F8F8F8;
$gris_5: #939393;
$gris_6: #3D3D3D;
$gris_7:#575757;
$gris_8:#424242; 
$gris-claro_2:#E1E1E1;