@import './var.scss';
@import './mixin.scss';

.tama_title {
    font-size: 0.833vw;
}

.certificados {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    input[type="radio"] {
        display: none;
        /* Oculta el radio botón original */
    }
}



.certificado {
    position: relative;
    padding-right: 3vw;
}

.label_filter {
    width: 4vw;
    height: 4vw;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5vw;
    cursor: pointer;


}

input[type="radio"]:checked+.label_filter {

    /* Estilo para cuando el botón está seleccionado */
    border: 1px solid $naranja;
    box-shadow: 0px 0px 15px 0px #F4792173;
}

.resultado {
    //display: flex;
    //justify-content: space-between;
    //align-items: flex-start;
    border-left: 2px solid $naranja;
    //border-top: 1px solid $gris-claro;
    //border-right: 1px solid $gris-claro;
    //border-bottom: 1px solid $gris-claro;
    padding: 1vw;
    border-radius: 0vw 0.521vw 0.521vw 0vw;
    box-shadow: 0px 0px 4px 0px #0000001A;

}

.texto {
    // width: 80%;
    position: relative;
    margin-right: 1vw;
}

.item {
    width: 3vw;
    height: 3vw;
    background-color: $gris-claro;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    position: relative;
    //margin-right: 0.5vw;
    font-size: 1vw;
    padding: 0.4vw;
}

.btn {
    display: flex;
    align-items: center;
}

.btn_descarga {
    font-size: 1vw;
}

// Con esta clase le activo el hover a los items de Q miden
.containerCertifica {
    cursor: pointer;

    .Textcertifica {
        opacity: 0;
        line-height: 1;
        display: none;
    }

    &:hover {
        .Textcertifica {
            opacity: 1;
            display: block;
        }
    }
}

.containerMide {
    //width: 80%;
}

//big desktop
@media (min-width: 1920px) {

    .item {
        font-size: 2rem;
    }
}

//big desktop
@media (min-width: 1920px) {
    .containerMide {
        //width: 100%;
    }

    .texto {
        width: 90%;
    }
}

//desktop
@media (min-width: 1200px) {}

@media (max-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .tama_title {
        font-size: 0.833rem;
    }

    .label_filter {
        width: 4rem;
        height: 4rem;
        padding: 0.5rem;
    }

    .containerMide {
        //width: 100%;
    }

    .texto {
        //width: 60%;
        margin-right: 1rem;
    }

    .btn_descarga {
        font-size: 0.8rem;
    }

    .item {
        width: 3rem;
        height: 3rem;
        padding: 0.4rem;
    }

    .resultado {
        padding: 1rem;
        border-radius: 0rem 0.521rem 0.521rem 0rem;
    }

    .item {
        font-size: 1rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .containerMide {
        //width: 50%;
    }

    .texto {
        width: 100%;
    }

}

@media (max-width: 768px) {
    .tama_title {
        font-size: 0.833rem;
    }

    .label_filter {
        width: 4rem;
        height: 4rem;
        padding: 0.5rem;
    }

   

    .btn_descarga {
        font-size: 0.8rem;
    }

    .item {
        width: 3rem;
        height: 3rem;
        padding: 0.4rem;
    }

    .resultado {
        padding: 1rem;
        border-radius: 0rem 0.521rem 0.521rem 0rem;
    }

    .item {
        font-size: 1rem;
    }
}

//only phone
@media (max-width: 576px) {
    .containerMide {
        width: 100%;
    }

    .resultado {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .tama_title {
        font-size: 0.833rem;
    }

    .label_filter {
        width: 4rem;
        height: 4rem;
        padding: 0.5rem;
    }

    .texto {
        width: 100%;
    }

    .btn_descarga {
        font-size: 0.8rem;
    }

    .item {
        width: 3rem;
        height: 3rem;
        padding: 0.4rem;
    }

    .resultado {
        padding: 1rem;
        border-radius: 0rem 0.521rem 0.521rem 0rem;
    }

    .item {
        font-size: 1rem;
    }
}