@-webkit-keyframes slide-in-bottom {
    0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    }
}
@keyframes slide-in-bottom {
    0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    }
}

@-webkit-keyframes slide-in-right {
    0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    }
}



@-webkit-keyframes slide-in-top {
    0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    }
}
@keyframes slide-in-top {
    0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    }
}

@-webkit-keyframes slide-in-left {
    0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    }
}
@keyframes slide-in-left {
    0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    }
}


@-webkit-keyframes activeLoad {
        0% {
            width: 0%;
        }
        60%{
            width: 100%;
        }
        90%{
            width: 100%;
            background-color: $gris-claro;
        }
        100% {
            width: 100%;
            background-color: white;
        }
    }
    
    @keyframes activeLoad {
        0% {
            width: 0%;
        }
        60%{
            width: 100%;
        }
        90%{
            width: 100%;
            background-color: $gris-claro;
        }
        100% {
            width: 100%;
            background-color: white;
        }
    }