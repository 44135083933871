@import './mixin.scss';
@import './var.scss';

.google_map_container {
    height: 70vh;
}

@media (min-height:1080px) {
    .google_map_container {
        height: 30vh;
    }
}

.select_ubicacion_container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.select_ubicacion_separator {
    background-color: $naranja;
    height: 0.2px;
    width: 75%;
    margin: 5px 0 5px 0
}

@media (min-width: 576px) {
    .select_ubicacion_container {
        display: flex;
        flex-direction: row;
    }

    .select_ubicacion_separator {
        background-color: $naranja;
        width: 1px;
        margin: 20px;
        height: auto;
    }
}

.labels {
    position: relative;
    padding-bottom: 0.5vw;
    color: $gris;
    font-size: 1.250vw;
}

.select_proyecto {
    option::after {
        font-size: 0.833vw;
    }
}

.select_mapa {
    font-size: 0.69vw;
}

.selectores {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.wpSelect {
    width: 40%;
}

.lista {
    list-style: none;
    padding: 0;

    .li_item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 2vw;
        font-size: .9rem;
        position: relative;
        margin-bottom: 0.8vw;
    }

    .li_item_min {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 2vw;
        font-size: .9rem;
        position: relative;
        margin-bottom: 1.3vw;
    }

    .li_item::before {
        content: '\2022';
        color: $naranja;
        display: inline-block;
        //width: 0.8em;
        font-size: 3vw;
        margin-right: 0.8vw;

    }

    .li_item_min::before {
        content: '\2022';
        color: $naranja;
        display: inline-block;
        //width: 0.8em;
        font-size: 3vw;
        margin-right: 0.8vw;

    }
}

//big desktop
@media (min-width: 1920px) {
    .lista {

        .li_item {

            height: 2rem;
            font-size: 1rem;
            margin-top: 1rem;
        }
    }
}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .wpSelect {
        width: 100%;
        position: relative;
        margin-top: 1rem;
    }

    .select_mapa {
        font-size: 0.9rem;
    }

    .lista {

        .li_item {

            height: 2rem;
            font-size: 1rem;
            margin-top: 0.8rem;
        }

        .li_item::before {
            font-size: 3rem;
            margin-right: 0.8rem;
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .select_mapa {
        font-size: 0.9rem;
    }

    .wpSelect {
        width: 100%;
        position: relative;
        margin-top: 1rem;
    }

    .lista {

        .li_item {

            height: 1.5rem;
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }

        .li_item::before {
            font-size: 2.5rem;
        }
    }
}

//only phone
@media (max-width: 767px) {
    .select_mapa {
        font-size: 0.9rem;
    }

    .lista {
        .li_item {
            height: 1.5rem;
            font-size: 0.9rem;
        }

        .li_item::before {
            font-size: 2.5rem;
            margin-right: 0.9rem;
        }
    }
}

//only phone
@media (max-width: 576px) {

    .lista {
        .li_item {
            height: 1.5rem;
            font-size: 0.9rem;
        }

        .li_item::before {
            font-size: 2.5rem;
        }
    }

    .wpSelect {
        width: 100%;
        position: relative;
        padding-bottom: 0.5rem;
        margin-top: 1rem;
    }

    .selectores {
        display: block;
        justify-items: center;
    }

    .labels {
        font-size: 1rem;
    }

    .select_mapa {
        font-size: 1rem;
    }
}