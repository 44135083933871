@import "./var.scss";
@import "./mixin.scss";
@import "./keyframes.scss";


// first desktop

.header {
    width: 100%;
    box-shadow: 0px 4px 4px 0px #0000000D;
}

.logo {
    width: 6vw;
}

.wpNav,
.wpNavInversionista {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbarInversionista {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}


.subNav {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 10;

    li {
        position: relative;
        padding: 0.1rem 0;

        &:hover {
            .dropSubNav {
                visibility: visible;
                opacity: 1;
                top: 100%;
            }
        }
    }
}

.subNavItem {
    font-size: 0.8vw;
    text-decoration: none;
    color: white;
    padding: 0.1vw 1.5vw;
}

.dropSubNav {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 90%;
    left: 0;
    visibility: hidden;
    opacity: 0;
    background-color: white;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    @include toAnim(0.3s);

    li {
        a {
            width: 100%;
            color: $gris;
            white-space: nowrap;

            &:hover {
                color: $naranja;
            }
        }
    }
}

.nav {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.navItem {
    height: 100%;
}

.navLink {
    font-size: 1vw;
    text-decoration: none;
    padding: 1.8vw 0.5vw;
    color: $gris;
    font-family: 'Roboto';
    font-weight: 500;
}

.active {
    color: $naranja;
}

.searcher {
    width: 18vw;
    border: 1px solid $naranja;
    display: flex;
    align-items: center;
    padding: 0.5vw 0.8vw;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
}

.searcher.active {
    visibility: visible;
    opacity: 1;
    @include toAnim(0.3s);
}

.btnSearch,
.btnSearchactive {
    color: $gris;
    background-color: transparent;
    border: 0;
    font-size: 1.2vw;
    line-height: 100%;
    display: inline-block;
    padding-right: 0.8vw;
}

.input {
    border: 0;
    font-size: 0.8vw;
    line-height: 100%;
    display: inline-block;

    &:focus {
        outline: none;
    }
}

.overlay,
.btnClose,
.btnMenu,
.perfil_menu,
.hr {
    display: none;
}

.desplegable_menu {
    position: absolute;
    background: #fff;
    box-shadow: 0px 0px 15px 0px #00000040;
    padding: 1.2vw 1vw;
    top: 3vw;
    right: 0;
    z-index: 99;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.caja_menu {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.text_menu {
    white-space: nowrap;
}

.btndesplegable {
    border: 0;
    background-color: transparent;
}

// big desktop
@media only screen and (min-width: 1920px) {}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {}


@media only screen and (min-width: 1200px) {

    /* .header{
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10001;
        background-color: white;
    } */

}

// big tablet LG
@media (max-width: 1200px) {
    .searcher {
        width: auto;
    }
}


@media (max-width: 992px) {}

// all tablet 
@media (min-width: 768px) and (max-width: 1200px) {
    .subNavItem {
        font-size: 0.8rem;
    }

    .navLink {
        font-size: 0.8rem;
    }

    .input {
        font-size: 0.7rem;
    }

    .btnSearch,
    .btnSearchactive {
        font-size: 1rem;
    }
}

// tablet portrait
@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .navbarInversionista {

        ul {
            li {
                width: 30%;
            }
        }


    }

    .wpNavInversionista {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 5.5rem;
    }

    .desplegable_menu {
        padding: 1.2rem 1rem;
        top: 3rem;
    }

    .nav {
        margin-bottom: 1rem;
    }

    .wpNav {
        flex-wrap: wrap;
        padding: 0.5rem;
    }

    .wpNav.inversionista {
        display: contents;

        .nav {
            margin-bottom: 0rem;
            margin-left: 2rem;
        }
    }

    .searcher {
        padding: 0.5rem 0.8rem;
    }

    .btnSearch {
        font-size: 1rem;
    }

    .btnSearchactive {
        padding-right: 1rem ;
        font-size: 1rem;
    }

    .subNav {
        li {
            padding: 0.3rem 0;
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .desplegable_menu {
        padding: 1.2rem 1rem;
        top: 3rem;
    }

}

// tablet MD
@media (max-width: 768px) {

    .navLink {
        font-size: 0.8rem;
    }

    .input {
        font-size: 0.7rem;
    }

    .searcher {
        padding: 0.5rem 0.8rem;
      
    }

    .btnSearch {
        font-size: 1rem;
    }
    .btnSearchactive {
        padding-right: 1rem ;
        font-size: 1rem;
    }

    .desplegable_menu {
        padding: 1.2rem 1rem;
        top: 3rem;
    }

    .subNavItem {
        font-size: 0.8rem;
    }
}

// tablet MD
@media (max-width: 767px) {
 
    .searcher {
        visibility: visible;
        opacity: 1;
      
    }
    .btnSearchactive {
       visibility: hidden;
       opacity: 0;
    }
}


// only mobile

@media (max-width: 576px) {

    .navbarInversionista {

        ul {
            justify-content: center;

            li {
                width: 100%;
            }
        }


    }

    .desplegable_menu {
        padding: 1.2rem 1rem;
        top: 3rem;
    }

    .header {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        background-color: white;
    }

    .overlay {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1000;
        visibility: hidden;
        opacity: 0;
        @include toAnim(0.2s);
    }

    /*.block {
        width: calc(100% / 5);
        height: calc(100% / 9);
        opacity: 0;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        background-color: rgba($naranja, 0.9);
        //        @include toAnim(0.3s);
    }*/

   /* .visibleBlock {
        -webkit-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.28);
        box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.28);
        background-color: rgba($ocre, 1);
        position: relative;
        z-index: 1;
    }*/

    .visibleBlockTop {
       // opacity: 1;
       // -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        //animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .visibleBlockLeft {
        //opacity: 1;
       // -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
       // animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .visibleBlockRight {
        //opacity: 1;
       // -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        //animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .visibleBlockBottom {
       // opacity: 1;
       // -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        //animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /*.visibleBlockWhite {
        background-color: white;
        border: 3px solid white;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        @include toAnim(0.1s);
    }*/

    .overlayShow {
        visibility: visible;
        opacity: 1;
    }

    .bgWhite {
        background-color: rgba(white, 1);
    }

    .btnMenu {
        display: block;
        background-color: transparent;
        font-size: 2rem;
        color: $naranja;
        border: 0;
    }

    .perfil_menu {
        display: block;
    }

    .reverse {
        .child_menu:first-child {
            margin-right: auto;
        }

        .child_menu::not(:first-child) {
            margin-left: auto;
        }

        // .child_menu:nth-child(2) {
        //     margin-left: auto;
        // }
        // .child_menu:nth-child(3) {
        //     margin-left: auto;
        // }
    }

    .wpNav {
        width: 90%;
        position: fixed;
        top: 0%;
        left: 5%;
        transform: translateY(-50%);
        z-index: 1001;
        /* background-color: rgba(white, 1); */
        padding: 2rem;
        display: block;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        /* -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.28);
        -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.28);
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.28); */
        visibility: hidden;
        opacity: 0;
        @include toAnim(0.1s);
    }

    .wpNav.visible {
        visibility: visible;
        opacity: 1;
        top: 50%;
    }

    .wpNav .logo {
        width: 7rem;
        visibility: hidden;
        opacity: 0;
        @include toAnim(0.5s);
    }

    .wpNav .logoShow {
        visibility: visible;
        opacity: 1;
    }

    .navbar,
    .navbarInversionista {
        visibility: hidden;
        opacity: 0;
        @include toAnim(0.5s);
    }


    .wpShow {
        visibility: visible;
        opacity: 1;
    }

    .btnClose {
        font-size: 3rem;
        background-color: transparent;
        display: block;
        margin: 2rem auto;
        border: 0;
        color: $naranja;
        visibility: hidden;
        opacity: 0;
        @include toAnim(0.5s);
    }

    .btnCloseShow {
        visibility: visible;
        opacity: 1;
    }

    .navbar,
    .navbarInversionista {
        display: block;
        text-align: center;
    }

    .nav {
        display: block;
        height: auto;
        padding: 3rem 1rem;
    }

    .navLink {
        font-size: 1.3rem;
        padding: 0 0 0.8rem 0;
        display: inline-block;
    }

    .logo {
        width: 5rem;
        display: block;
    }

    .hr {
        display: block;
        display: none;
        border-color: $naranja;
        border-width: 2px;
    }

    .btnSearch {
        font-size: 1rem;
        padding-right: 0.8rem;
    }

    .searcher {
        padding: 0.5rem 0.8rem;
    }

    .input {
        font-size: 1rem;
    }

    .subNavItem {
        font-size: 0.8rem;
        display: inline-block;
    }

    .subNav {
        li {
            padding: 0.5rem 0;
        }
    }

}