@import './mixin.scss';
@import './var.scss';

.form_contactos {
	box-shadow: 0px 0px 2px 2px $gris-claro;
	padding: 0.5vw 2vw;
	border-radius: 20px;
}



/*.body_form {
    position: relative;
    max-height: 28vw;
    overflow: auto;
}

.body_form::-webkit-scrollbar {
    display: none;
}*/

/*.body_form::-webkit-scrollbar-thumb {
    background: #76A1A7;
    border-radius: 1rem;

 }*/

.tama_phone {
	font-size: 1vw;
}

.labels,
.inputs,
.select {
	width: 100%;
	text-transform: capitalize;
	font-size: 0.8vw;
	display: inline-block;
	vertical-align: top;
}

.labels {
	margin-top: 0;
}

.labels_check {
	font-size: 1vw;
	vertical-align: top;
	display: inline-block;
}

.input_check {

	border: 1px solid $naranja ;
}

.form-check-input[type=checkbox] {
	border-radius: 50%;
}

.inputs {
	border: 0;
	padding: 0;
}

.select {
	border: 0;
	display: inline-block;
}

.formgrupos {
	width: 100%;
	position: relative;
	display: inline-block;
	border: 1px solid $gris;
	padding: 0.5vw 1.5vw;
	line-height: 100%;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}



.wpSelect {
	padding: 0.5vw 0.5vw 0.5vw 1.5vw;
	border-right: 1px solid $gris;
}

.wpSelect .select {
	padding: 0;
}

.wpInput {
	padding: 0.5vw 0.5vw 0.5vw 1.5vw;
}

.wpInput {
	width: 60%;

	input {
		padding: 0;
		margin: 0;
	}
}

.wpSelect {
	width: 40%;
}

.btn_form {
	width: 100%;
	@include toAnim(0.3s);

	&:hover {
		background-color: $ladrillo;
	}
}

.labelsComunicate {
	/* font-size: 24px; */
	font-size: 1.2vw;
	font-weight: 400;
}

.selectComunicate {
	font-size: 0.8vw;
	border: 0;
	display: inline-block;
	width: 100%;
	text-transform: uppercase;
	vertical-align: top;

	&::after {
		content: "\25BC";
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		color: $naranja;
	}
}

.textContent {
	/* font-size: 14px; */
	font-size: 0.7vw;
}

.scrollform {
	max-height: 100vh;
	overflow: auto;
}

@media (max-width: 992px) {
	.textContent {
		/* font-size: 14px; */
		font-size: 0.7rem;
	}
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.form_contactos {
		padding: 1.5rem 2rem;
	}

	.labels,
	.inputs,
	.select {
		font-size: 0.9rem;
	}

	.labelsComunicate {
		/* font-size: 24px; */
		font-size: 0.9rem;
	}

	.selectComunicate {
		font-size: 0.8rem;
	}
}


@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.form_contactos {
		padding: 0.5rem 2rem;
	}

	.labels,
	.inputs,
	.select {
		font-size: 0.9rem;
	}
}

// tablet MD
@media (min-width: 577px) and (max-width: 767px) and (orientation: landscape) {

	.labels,
	.inputs,
	.select {
		font-size: 0.8rem;
	}


	.labelsComunicate {
		/* font-size: 24px; */
		font-size: 0.9rem;
	}

	.selectComunicate {
		font-size: 0.8rem;
	}

	.form_contactos {
		padding: 1.5rem 2rem;
	}
}

@media (max-width: 576px) {
	.selectComunicate {
		font-size: 0.8rem;
	}

	.labelsComunicate {
		/* font-size: 24px; */
		font-size: 0.9rem;
	}

	.body_form {
		max-height: none;
	}

	.form_contactos {
		padding: 2rem;
	}

	.labels,
	.inputs,
	.select {
		font-size: 0.8rem;
	}

	.labels_check {
		font-size: 0.8rem;
	}

	.tama_phone {
		font-size: 1.3rem;
		padding-right: 0.5rem;
	}



}