@import './var.scss';
@import './mixin.scss';

.btnTipo {
    display: flex;
    align-items: center;
    width: 100%;

    input[type="radio"] {
        display: none;
        /* Oculta el radio botón original */
    }

    input[type="radio"]:checked+.label_boton {
        background-color: $naranja;
        /* Estilo para cuando el botón está seleccionado */
        color: #fff;
        border: 1px solid $naranja;
    }
}

.label_boton {
    display: inline-block;
    border: 1px solid $naranja;
    border-radius: 0.5vw;
    cursor: pointer;
    position: relative;
    //margin-right: 0.6vw;
    font-size: 1.1vw;
    padding: 0.7vw 0vw;
    color: $gris;
    line-height: 1.1;

}

.btn_select {
    text-align: center  ;
    width: 50%;
  
}

@media (max-width: 767px) {
    .label_boton {
        padding: 0.5rem 0.8rem;
        border-radius: 0.5rem;
        position: relative;
        margin-right: 0.6rem;
        font-size: 1rem;
    }
}

//only phone
@media (max-width: 576px) {

    .btnTipo {
        display: block;
        align-items: center;
    }

    .label_boton {
        width: 100%;
    }

    .btn_select {
        position: relative;
        padding-bottom: 1rem;
        width: 100%;
    }
}