@import "./var.scss";
@import "./fonts.scss";
@import "./mixin.scss";

.containerModal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1050;
    background: linear-gradient(rgba(44, 42, 41, .8) 0%, #2C2A29 100%);
}

.containerBtnchat {
    position: fixed;
    bottom: 15px;
    right: 1.5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1051;

}

.Notvisible{
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}

.contenidoBtnchat {
    visibility:hidden;
    opacity: 0;
    height: 0;
    width: 0;
    @include toAnim(0.3s);

    ul {
        list-style: none;
    }
}

.contenidoBtnchat.activo {
    visibility: visible;
    opacity: 1;
    height: auto;
    width: auto;
    @include toAnim(0.3s);
}

.btnchat,
.containerImg {
    height: 4vw;
    width: 4vw;
    background-color: $naranja;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}

.iconChat {
    font-size: 2.3vw;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgstyle {
    height: 2.5vw;
    object-fit: cover;

}

.containerImg {
    background-color: white;
}

.fzNombre {
    font-size: 1.2vw;
}



//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .containerBtnchat {
        bottom: 15px;
        right: 1.5rem;
    }

    .imgstyle {
        // width: 4.5rem !important;
        height: 1.9rem;
    }

    .fzNombre {
        font-size: 1.2rem;
    }

    .btnchat,
    .containerImg {
        height: 4.5rem;
        width: 4.5rem;
    }

    .iconChat {
        font-size: 2.8rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

    .containerBtnchat {
        bottom: 75px;
        right: 1.5rem;

    }

    .imgstyle {
        // width: 4.5rem !important;
        height: 2rem;
    }

    .fzNombre {
        font-size: 1.2rem;
    }

    .btnchat,
    .containerImg {
        height: 4.5rem;
        width: 4.5rem;

    }

    .iconChat {
        font-size: 2.8rem;
    }
}

@media only screen and (min-width: 990px)  and (max-width: 1200px){
    .containerBtnchat {
        bottom: 15px !important;
        right: 1.5rem;

    }
}

//only MD
@media (max-width: 767px) {

    .containerBtnchat {
        bottom: 75px;
        right: 1.5rem;

    }

    .imgstyle {
        // width: 2.8rem !important;
        height: 1.5rem;
    }

    .fzNombre {
        font-size: 1.2rem;
    }

    .btnchat,
    .containerImg {
        height: 2.8rem;
        width: 2.8rem;

    }

    .iconChat {
        font-size: 1.8rem;
    }
}

//only phone
@media (max-width: 576px) {

    .contenidoBtnchat {
        display: none;
    }

    .contenidoBtnchat.activo {
        display: block;
    }


    .containerBtnchat {
        bottom: 75 px;
        right: 1.5rem;
    
    }

    .imgstyle {
        //width: 3.5rem !important;
        height: 2rem;
    }

    .btnchat,
    .containerImg {
        height: 3.5rem;
        width: 3.5rem;

    }

    .iconChat {
        font-size: 2rem;
    }
}