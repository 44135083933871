@import './var.scss';
@import './mixin.scss';

.urbanismoslider {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.pie {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

}

.footer {
    background: #000000;
    background: linear-gradient(to right, rgb(87, 88, 90), rgba(31, 29, 29, 0.596) 100%);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.4vw;
}

.items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 1.5vw;
}

.icon {
    color: $naranja;
    font-size: 1.3vw;
    position: relative;
    margin-bottom: 0.2vw;
}

.iconImg {
    height: 1.3vw;
    display: block;
}

.nombre {
    color: #fff;
}

.btncloseModal {
    border: 0;
    background-color: transparent;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .iconImg {
        height: 1.3rem;
    }

    .footer {
        padding: 0.4rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .iconImg {
        height: 1.3rem;
    }

    .footer {
        padding: 0.4rem;
    }
}

@media (max-width: 767px) {

    .iconImg {
        height: 1.3rem;
    }

    .footer {
        padding: 0.4rem;
    }
}

//only phone
@media (max-width: 576px) {
    .icon {
        font-size: 2rem;
    }

    .nombre {
        font-size: 1rem;
    }

    .footer {
        padding: 0.5rem;
    }
}