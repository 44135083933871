@import './var.scss';
@import './mixin.scss';

.iconClose {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    color: $gris_2;
    font-size: 2vw;
    font-weight: 500;
}

.hpIgm {
    max-height: 40vw;
}

.pie {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

}

.footer {
    background: #000000;
    background: linear-gradient(to right, rgb(87, 88, 90), rgba(31, 29, 29, 0.596) 100%);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 1.5vw;
}

.icon {
    color: $naranja;
    font-size: 2vw;
    position: relative;
    margin-bottom: 0.2vw;
}

.nombre {
    color: #fff;
}

.btncloseModal {
    border: 0;
    background-color: transparent;
}

.link{
    color: $naranja;
    border-bottom: 1px solid $naranja;
    line-height: normal;
}

// estilos modal ayuda para los pagos en linea 

.modalAyuda,
.modal_postularse,
.modalContacto {
    position: fixed;
    bottom: 7vw;
    right: 1.5vw;
    z-index: 9999;
    @include toAnim(0.3s);
    background-color: white;
    opacity: 0;
    visibility: hidden;
    padding: 1vw 1vw 2vw 1vw;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0 1px 5px rgb(0, 0, 0, 0.25);
}

.modalAyuda {
    max-width: 15%;
}

.modal_postularse {
    max-height: 100vh;
    overflow-y: auto;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.2);
}

.modalContacto {
    bottom: 15vw;
    right: 6vw;
    z-index: 999991;
}

.showModalAyuda {
    opacity: 1;
    visibility: visible;
}

.btnClose {
    background-color: transparent;
    border: 0;
}

.circuloImg {
    width: 8vw;
    height: 8vw;
    background-color: $naranja;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.img {
    height: 5vw;
    position: relative;
    margin-left: 1.5vw;
}

.numero {
    position: absolute;
    top: 0;
    left: 1vw;
}

.BodyTarjeta {
    cursor: pointer;
}

.urbanismoslider {
    position: relative;
    //max-width: 20%;
}

.contanedorModalGalery {
    width: 50%;
}

.containerNotification {
    position: relative;

    .iconCloseNotifi {
        position: absolute;
        top: 0.5vw;
        right: 0.5vw;
        cursor: pointer;
        background-color: $gris_2;
        border-radius: 50%;
        width: 1.8vw;
        height: 1.8vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2vw;
    }

    .informacionNotifica {
        position: absolute;
        bottom: 2vw;
        left: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        
    }
}

.containerNotification::before {
    position: absolute;
    content: '';
    left: 0 !important;
    top: 0%;
    bottom: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3), transparent);
    width: 100% !important;
}

.icon_modal_config {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
}

.container_modalInstall {
    background-color: white;
    position: fixed;
    top: 0;
    left: auto;
    z-index: 10000;
    width: 70%;
    padding: 0.5vw;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1490196078);
    display: flex;
    align-items: center; 
    @include toAnim(0.3s);
}

.container_modalInstall.Notactive{
    transform: translateY(-10rem);
    @include toAnim(0.3s);
}

@media (min-width: 1921px) {
    @media (max-height: 1080px) {
        .modal_postularse {
            right: 0.8rem;
            bottom: 0.2rem;
        }
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .icon_modal_config {
        font-size: 2rem;
    }

    .containerNotification {
        position: relative;

        .iconCloseNotifi {
            top: 0.5rem;
            right: 0.5rem;
            width: 1.8rem;
            height: 1.8rem;
            font-size: 2rem;
        }

        .informacionNotifica {
            bottom: 2rem;
        }
    }

    .iconClose {
        font-size: 2rem;
    }

    .modalContacto {
        bottom: 16rem;
        right: 6.8rem;
        padding: 1rem 1rem 2rem 1rem;
    }

    .modalAyuda,
    .modal_postularse {
        bottom: 7rem;
        right: 1.5rem;
        padding: 0.5rem 1rem;
    }

    .modalAyuda {
        max-width: 25%;
    }

    .circuloImg {
        width: 8rem;
        height: 8rem;
    }

    .img {
        height: 5rem;
        margin-left: 1.5rem;
    }

    .numero {
        left: 1.2rem;
    }

    .contanedorModalGalery {
        width: 60%;
    }

}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .icon_modal_config {
        font-size: 2rem;
    }

    .containerNotification {
        position: relative;

        .iconCloseNotifi {
            top: 0.5rem;
            right: 0.5rem;
            width: 1.8rem;
            height: 1.8rem;
            font-size: 2rem;
        }

        .informacionNotifica {
            bottom: 2rem;
        }
    }

    .modalContacto {
        bottom: 16rem;
        right: 6.8rem;
        padding: 1rem 1rem 2rem 1rem;
    }

    .modalAyuda,
    .modal_postularse {
        bottom: 7rem;
        right: 1.5rem;
        padding: 0.5rem 1rem;
    }

    .modalAyuda {
        max-width: 28%;
    }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
    .contanedorModalGalery {
        width: 70%;
    }

    .iconClose {
        font-size: 1.5rem;
    }

    .modalAyuda,
    .modal_postularse {
        right: 1.5rem;
        padding: 0.5rem 1rem;
    }

    .modalAyuda {
        max-width: 40%;
        bottom: 2rem;
    }

    .modal_postularse {
        bottom: 0.2rem;
    }

    .iconClose {
        font-size: 1.5rem;
    }

    .circuloImg {
        width: 8rem;
        height: 8rem;
    }

    .img {
        height: 5rem;
        margin-left: 1.5rem;
    }

    .numero {
        left: 1.2rem;
    }

    .modalContacto {
        bottom: 12rem;
        right: 5rem;
        padding: 1rem 1rem 2rem 1rem;
    }

    .containerNotification {
        position: relative;

        .iconCloseNotifi {
            top: 0.5rem;
            right: 0.5rem;
            width: 1.8rem;
            height: 1.8rem;
            font-size: 2rem;
        }

        .informacionNotifica {
            bottom: 2rem;
        }
    }

    .icon_modal_config {
        font-size: 2rem;
    }

    .container_modalInstall {
        width: 95%;
        padding: 0.5rem;
    }
}

//only phone
@media (max-width: 576px) {

    .container_modalInstall {
        width: 90%;
        padding: 0.5rem;
    }

    .icon_modal_config {
        font-size: 2rem;
    }

    .containerNotification {
        position: relative;

        .iconCloseNotifi {
            top: 0.5rem;
            right: 0.5rem;
            width: 1.8rem;
            height: 1.8rem;
            font-size: 2rem;
        }

        .informacionNotifica {
            bottom: 2rem;
        }
    }

    .modalContacto {
        bottom: 16rem;
        right: 6rem;
        padding: 1rem 1rem 2rem 1rem;
    }

    .contanedorModalGalery {
        width: 100%;
    }

    .modalAyuda,
    .modal_postularse {
        bottom: 9.5rem;
        padding: 0.5rem 1rem;
    }

    .modalAyuda {
        max-width: 55%;
        right: 1.5rem;
    }

    .modal_postularse {
        right: 0.8rem;
        width: 90%;
    }

    .iconClose {
        font-size: 1.5rem;
    }

    .circuloImg {
        width: 8rem;
        height: 8rem;
    }

    .img {
        height: 5rem;
        margin-left: 1.5rem;
    }

    .numero {
        left: 1.2rem;
    }

    .hpIgm {
        max-height: initial;
    }
}

@media (max-height: 600px) {
    .modal_postularse {
        right: 0.8rem;
        bottom: 0.2rem;
    }
}