@import "./var.scss";
@import "./mixin.scss";

/* The Control */
.Control {
	//width: 10vw;
	min-width: 3vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid $naranja;
	padding: 0.5vw;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	overflow: hidden;
	@include toAnim(0.3s);

}

/* Hide the browser's default checkbox */
.Control .input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.title {
	width: 100%;
	font-size: 0.8vw;
	position: relative;
	z-index: 1;
	display: inline-block;
	@include toAnim(0.3s);
	color: $gris;
	font-weight: 400;
}

.regular.title {
	font-size: 1.2vw;
}

.wpIcon {
	width: 100%;
	min-height: 5vw;
	display: flex;
	align-items: center;
}

.icon {
	position: relative;
	color: $naranja;
	width: 2vw;
	height: 2vw;
	z-index: 1;
	@include toAnim(0.3s);
}

.Control.active {
	.title {
		color: white;
		font-weight: 400;
	}

	.icon {
		color: white;
		filter: brightness(0) invert(1);
	}
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 0;
	background-color: white;
	@include toAnim(0.3s);
}

/* On mouse-over, add a grey background color */
.Control:hover .input~.checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.Control .input:checked~.checkmark {
	background-color: $naranja;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.Control .input:checked~.checkmark:after {
	display: block;
}

.last_child {
	margin-right: 0rem !important;
}

// big desktop
@media only screen and (min-width: 1921px) {}

@media (max-width: 1600px) {}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {

	.regular.title {
		font-size: 1rem;
	}

}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.title {
		font-size: 1rem;
	}

	.icon {
		/* font-size: 2.2rem; */
		width: 1.5rem;
		height: 1.5rem;
	}

	.Control {
		width: 10rem;
		min-width: 3rem;
		padding: 0.5rem;
	}
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.title {
		font-size: 1.1rem;
	}

	.icon {
		font-size: 2.2rem;
		width: 1.5rem;
		height: 1.5rem;
	}

	.Control {
		width: 10rem;
		min-width: 3rem;
		padding: 0.5rem;
	}

	.wpIcon {
		min-height: 4rem;
	}
}

@media (max-width: 768px) {
	.regular.title {
		font-size: 1rem;
	}
}


/* // tablet MD
@media (min-width: 577px) and (max-width: 767px) and (orientation: landscape) {
	.title {
		font-size: 1rem;
	}

	.icon {
		font-size: 2.2rem;
	}

	.Control {
		width: 10rem;
		min-width: 3rem;
	}
} */

// only mobile
@media (max-width: 767px) {

	.Control {
		width: 10rem;
		min-width: 3rem;
		padding: 0.5rem;
	}

	.title {
		font-size: 1rem;
	}

	.icon {
		font-size: 2.2rem;
	}
}

@media (max-width: 576px) {
	.title {
		font-size: 1rem;
	}

	.icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.Control.movilSpeciales {
		border: none;
		padding: 0rem;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;

		.title {
			font-size: 0.7rem;
		}
	}

	.Control.movilSpeciales .input:checked~.checkmark {
		background-color: transparent;
		border-bottom: 5px solid $naranja;
		
	}

	.Control.movilSpeciales.active {
		.title {
			color: $naranja;
			text-shadow: 2px 3px 3px rgba(247, 242, 242, 0.89);
			padding-bottom: 0.5rem !important;
		}
	}

}