@import "./var.scss";
@import "./fonts.scss";

.modelo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.tama_fuente {
    font-size: 1vw;
}

.text_fuente {
    font-weight: bold;
}

.icon {
    color: $naranja;
    line-height: 0;
}

.tag_informativos {
    display: flex;
    align-items: center;
    border: 1px solid $naranja;
    border-radius: 0.521vw;
    padding: 0.5vw;
    position: relative;
    margin-right: 0.5vw;
    margin-bottom: 1vw;
}

.contenedor_tag {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.items_icon {
    position: relative;
    padding-right: 1vw;
}

.iconImg {
    max-height: 2.3vw;
}

.containerImg {
    cursor: pointer;
    position: relative;
}

.imgZoom_alto {
    max-height: 35vw;
}

.containerBtnZoom {
    position: absolute;
    top: 0;
    right: 0;

    .iconZoom {
        max-height: 2vw;
    }
}

//big desktop
@media (min-width: 1920px) {
    .tag_informativos {
        border-radius: 0.521rem;
        padding: 0.5rem;
        margin-right: 0.7rem;
        margin-bottom: 0.7rem;
    }

    .icon {
        font-size: 1.5rem;
    }

    .items_icon {
        padding-right: 1rem;
    }
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) {}

@media (max-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .containerBtnZoom {
        .iconZoom {
            max-height: 2.5rem;
        }
    }

    .imgZoom_alto {
        max-height: 35rem;
    }

    .iconImg {
        max-height: 2.3rem;
    }

    .modelo {
        display: flex;
        flex-direction: column;

    }

    .tama_fuente {
        font-size: 1rem
    }

    .tag_informativos {
        border-radius: 0.521rem;
        padding: 0.5rem;
        margin-right: 0.7rem;
        margin-bottom: 0.7rem;
    }

    .icon {
        font-size: 1.5rem;
    }

    .items_icon {
        padding-right: 1rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

    .containerBtnZoom {
        .iconZoom {
            max-height: 2rem;
        }
    }

    .imgZoom_alto {
        max-height: 30rem;
    }

    .iconImg {
        max-height: 2.3rem;
    }

}

@media (max-width: 767px) {

    .iconImg {
        max-height: 2.3rem;
    }

    .imgZoom_alto {
        max-height: 20rem;
    }

    .containerBtnZoom {
        .iconZoom {
            max-height: 2rem;
        }
    }
}

//only phone
@media (max-width: 576px) {

    .descri {
        width: 100%;
    }

    .imagen {
        width: 100%;
    }

    .modelo {
        display: flex;
        flex-direction: column;
    }

    .tama_fuente {
        font-size: 1rem
    }

    .tag_informativos {
        border-radius: 0.521rem;
        padding: 0.5rem;
        margin-right: 0.7rem;
        margin-bottom: 0.7rem;
    }

    .icon {
        font-size: 1.5rem;
    }

    .items_icon {
        padding-right: 1rem;
    }
}