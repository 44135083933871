@import "./var.scss";
@import "./fonts.scss";

.simulador {
    border: 1px solid $gris;
    border-radius: 1.042vw;
    padding: 0.4vw;
}

.header_simulador {
    background-color: $naranja;
    border-radius: 0.521vw;
    padding: 0.6vw 0vw;
}

.icon {
    color: #fff;
}

.parrafo {
    line-height: 1.2;
}

.parrafov2 {
    position: relative;
    font-size: 0.729vw;
}

.container_precios {
    position: relative;
    padding: 0vw 3vw;
}

.precio {
    font-size: 2.083vw;
}

.plazos {
    border: 2px solid $gris-claro;
    border-radius: 0.521vw;
    padding: 1vw;
}

.item_plazo {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.labels {
    font-size: 0.833vw;
}

.selctor_plazo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-bottom: 1.8vw;

    input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 0.8vw;
        height: 0.8vw;
        border: 1px solid $naranja;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        position: relative;
    }

    input[type="radio"]:checked::before {
        content: "";
        display: block;
        width: 0.8vw;
        height: 0.8vw;
        background-color: $naranja;
        /* Cambia este valor al color que desees */
        border-radius: 50%;
        border: 1px solid $naranja;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.iconv2 {
    font-size: 0.8vw;
    color: $gris;
}

.range_input {
    position: relative;

    input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        height: 0.521vw;
        background: $naranja;
        border-radius: 0.3vw;
        outline: none;
        margin: 1.042vw 0;
        border: 0vw solid #FFD2B2;
    }

    /* Personalizar el track del input range */
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 1.5vw;
        height: 1.5vw;
        background: $naranja;
        border: 0.37vw solid #fff;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -0.3vw;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.8vw;
        background: #FFD2B2;
        /* Establece el color de fondo del track */
        border-radius: 0.260vw;
    }
}

.valorCredito {
    position: relative;
    padding-right: 1.3vw;
}

.pointer {
    &:hover {
        cursor: pointer;

    }
}



.tooltip_show {
    position: relative;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .simulador {
        border-radius: 0.6rem;
        padding: 0.4rem;
    }

    .header_simulador {
        border-radius: 0.521rem;
        padding: 0.6rem 0rem;
    }

    .precio {
        font-size: 1.8rem;
    }

    .parrafov2 {
        font-size: 0.729rem;
    }

    .plazos {
        border-radius: 0.521rem;
        padding: 1rem;
    }

    .selctor_plazo {
        padding-bottom: 1.8rem;

        input[type="radio"] {
            width: 0.8rem;
            height: 0.8rem;
        }

        input[type="radio"]:checked::before {
            width: 0.8rem;
            height: 0.8rem;

        }
    }

    .labels {
        font-size: 0.833rem;
    }

    .range_input {
        input[type="range"] {
            height: 0.521rem;
            border-radius: 0.3rem;
            margin: 1.042rem 0;
        }

        input[type="range"]::-webkit-slider-thumb {
            width: 2rem;
            height: 2rem;
            border: 0.5rem solid #fff;
            margin-top: -0.5rem;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 1rem;
            border-radius: 0.260rem;
        }
    }

    .valorCredito {
        position: relative;
        padding-right: 1.4rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {}

//only phone
@media (max-width: 576px) {
    .simulador {
        border-radius: 0.6rem;
        padding: 0.4rem;
    }

    .header_simulador {
        border-radius: 0.521rem;
        padding: 0.6rem 0rem;
    }

    .precio {
        font-size: 1.8rem;
    }

    .parrafov2 {
        font-size: 0.729rem;
    }

    .plazos {
        border-radius: 0.521rem;
        padding: 1rem;
    }

    .selctor_plazo {
        padding-bottom: 1.8rem;

        input[type="radio"] {
            width: 0.8rem;
            height: 0.8rem;
        }

        input[type="radio"]:checked::before {
            width: 0.8rem;
            height: 0.8rem;

        }
    }

    .labels {
        font-size: 0.833rem;
    }

    .range_input {
        input[type="range"] {
            height: 0.521rem;
            border-radius: 0.3rem;
            margin: 1.042rem 0;
        }

        input[type="range"]::-webkit-slider-thumb {
            width: 1.8rem;
            height: 1.8rem;
            border: 0.5rem solid #fff;
            margin-top: -0.5rem;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 0.8rem;
            border-radius: 0.260rem;
        }
    }

    .valorCredito {
        position: relative;
        padding-right: 1.1rem;
    }
}