@import "./var.scss";
@import "./mixin.scss";

.bannerproyectos {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bannerAdquirir {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.tags_descripcion {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.tag_informativos {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #00000040;
    border-radius: 0.521vw;
    padding: 0.4vw 2.5vw 0.4vw 1.2vw;
    position: relative;
    margin-right: 1vw;
    margin-bottom: 1vw;
}

.icon_tags {
    color: $naranja;
    position: relative;
    padding-right: 0.2vw;
    font-size: 1.5vw;
}

.icon {
    color: $naranja;
    position: relative;
    padding-right: 0.2vw;
}

.iconTag {
    font-size: 1.2vw;
}

.stylegrupo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contenido {
    width: 50%;
}

.img {
    width: 40%;
}

.items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding-top: 2.3vw;
}


.tags {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.260vw;
}

.tama_text {
    font-size: 1.302vw;
}

.borderPicture {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 0.5vw;
    background: rgb(244, 121, 33);
    background: linear-gradient(90deg, rgba(244, 121, 33, 1) 74%, rgba(253, 232, 216, 1) 81%, rgba(255, 255, 255, 1) 100%);

}

.items_circulo {
    max-width: 90px;
    min-width: 75px;
}

.items_circuloMacro {
    width: 18%;
}

.isMacro {
    //border-bottom: 1px solid #fff;
}

.mx_height {
    max-height: 35vw;

}

.border_img {
    -webkit-border-radius: 10px 0px 0px 10px;
    -moz-border-radius: 10px 0px 0px 10px;
    border-radius: 10px 0px 0px 10px;
}

//big desktop
@media (min-width: 1920px) {
    .iconTag {
        font-size: 1.5rem;
    }
}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {
    .img {
        width: 50%;
    }

    .mx_height {
        max-height: 35vw;
        width: auto !important;
        object-fit: cover;
    }
}


@media (max-width: 1200px) {
    .borderPicture {
        padding: 0.5rem;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
}





@media (max-width: 767px) {

    .icon_tags {
        font-size: 1.2rem;
    }

    .items_circulo,
    .items_circuloMacro {
        width: 20%;
    }
}

//big desktop
@media (min-width: 1920px) {

    .items_circulo {
        width: 30%;
    }
}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .bannerAdquirir {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .contenido {
        width: 100%;
        padding-top: 1rem;
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding-top: 2.3rem;
    }

    .iconTag {
        font-size: 1.1rem;
    }

    .tama_text {
        font-size: 1rem;
    }

    .icon_tags {
        font-size: 1.5rem;
    }

    .tag_informativos {
        border-radius: 0.521rem;
        padding: 0.4rem 2.5rem 0.4rem 1.2rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }

    .items_circulo {
        width: 20%;
    }

    .mx_height {
        max-height: 35rem;
    }

}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 992px) and (orientation: portrait) {
    .bannerproyectos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding-top: 0rem;
    }


}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

    .items_circulo {
        width: 20%;
    }

    .mx_height {
        max-height: 35rem;
    }
}

//desktop
@media (min-width: 767px) and (max-width: 992px) {
    .bannerAdquirir {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .contenido {
        width: 100%;
        position: relative;
        padding-top: 1rem;
    }

    .img {
        width: 100%;
    }
}


@media (max-width: 767px) {
    .bannerAdquirir {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .bannerproyectos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding-top: 0rem;

    }

    .img {
        width: 100%;
    }

    .contenido {
        width: 100%;
        position: relative;
        padding-top: 1rem;
    }

    .mx_height {
        max-height: 35rem;
    }


}

@media only screen and (min-width: 576px) and (max-width: 992px) and (orientation: landscape) {
    .bannerproyectos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding-top: 0rem;

    }

    .bannerAdquirir {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .img {
        width: 100%;
    }

    .contenido {
        width: 100%;
        position: relative;
        padding-top: 1rem;
    }
}

//only phone
@media (max-width: 576px) {


    .tag_informativos {
        border-radius: 0.521rem;
        padding: 0.4rem 2.5rem 0.4rem 1.2rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }

    .iconTag {
        font-size: 1.1rem;
    }

    .tama_text {
        font-size: 1rem;
    }

    // .items_circulo,
    // .items_circuloMacro {
    //     // width: 30.33%;
    // }

    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 2.3rem;
    }

    .bannerAdquirir {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .contenido {
        width: 100%;
        position: relative;
        padding-top: 1rem;
    }

    .img {
        width: 100%;
    }

    .icon_tags {
        font-size: 1.2rem;
    }

    .bannerproyectos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding-top: 0rem;
    }

    .contenido {
        width: 100%;
        position: relative;
        padding-top: 1rem;
    }

    .img {
        width: 100%;
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding-top: 2.3rem;
    }
}